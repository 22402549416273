import Header from '../components/Header';
import './Portfolio.css';

const Portfolio=()=>{
    return(
        <div>
            
            <div className='row img-fluid' style={{backgroundImage:"url('images/image2.jpg')", height:"400px", width:"100%", borderRadius:"5px"}}>
            <p style={{textAlign:"left",color:"#ffbd59", fontSize:"48px", fontFamily:"ubuntu", position:"relative", top:"18px", left:"54px"}}>Portfolio</p>
        

  <div className="row row-cols-3 row-cols-md-3 cardstyle">
  <div className="col">
    <div className="card portstyle" >
      <img src="images/design.jpg" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Design</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>Firstly, Design the business according to the needs.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card portstyle">
      <img src="images/plan.jpg" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Plan</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>Planning is the next step to make a business successful.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card portstyle">
      <img src="images/required.jpg" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Requirements</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify",fontFamily:"ubuntu"}}>Collect the information according to the business plan.</p>
      </div>
    </div>
  </div><br/><br/><br/><br/>
  <div className="col">
    <div className="card portstyle">
      <img src="images/code.jpg" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Code</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>Now convert your imagination into reality.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card portstyle">
      <img src="images/deploy.jpg" height="163px" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Deploy</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>Finally, the business is digitalised which can be a part of everyone's life. </p>
      </div>
    </div>
  </div>
  <div className="col ">
    <div className="card portstyle">
      <img src="images/deploying.jpg"  className="card-img-top" alt="..."/>
      <div className="card-body vl">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Update</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>New Idea is all you required time to time to grow your business.</p>
      </div>
    </div>
  </div>
</div>

</div>
</div>
        
    );
}

export default Portfolio;