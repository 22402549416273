import './Header.css';
import '../App.css';
import { NavLink } from 'react-router-dom';

const Header = () => {


    return(
       
              
      
        <div className='row'>
          <div className='col-xl-1'>
          <img src="DiziTech.png" width="150px" height="150px"></img> 
          </div>
          <div className='col-xl-11'>
          <nav className="navbar navbar-expand-lg" >
      <div className="container-fluid"></div>
          <button className="navbar-toggler" type="button" data-bs-toggle="tab" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/" style={{color:"#0097b2", fontFamily:"ubuntu", fontSize:"22px"}} >Home</NavLink>
            </li>&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="nav-item">
              <NavLink className="nav-link" to="/portfolio" style={{color:"#0097b2", fontFamily:"ubuntu", fontSize:"22px"}}>Portfolio</NavLink>
            </li>&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="nav-item">
              <NavLink className="nav-link" to="/services" style={{color:"#0097b2", fontFamily:"ubuntu", fontSize:"22px"}}>Services</NavLink>
            </li>&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="nav-item">
              <NavLink className="nav-link" to="/about" style={{color:"#0097b2", fontFamily:"ubuntu", fontSize:"22px"}}>About</NavLink>
            </li>&nbsp;&nbsp;&nbsp;&nbsp;
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" style={{color:"#0097b2", fontFamily:"ubuntu", fontSize:"22px"}}>Contact</NavLink>
            </li>
          </ul>
        </div>
      
    </nav>
    
          </div>
          
        </div>
      
     
        
        
    
            
    
    );

}

export default Header;