import Footer from '../components/Footer';
import Header from '../components/Header';
import './Contact.css';

const Contact=()=>{
    return(
        <div>
            <div className='row' >
                <div className='col-xl-12'>
                <div className='content-box container-fluid' style={{textAlign:"left", position:"relative", left: "0px", top:"0px", height:"550px", fontSize:"48px",color:"#ffbd59", width:"1100px",backgroundImage:"url('images/image2.jpg')", borderRadius:"5px"}}>Contact Us
                <div className='row' style={{position:"absolute", right:"0", width:"500px"}}>
                <div class="row" >
                <div class="col" style={{position:"relative", top:"20px", fontFamily:"ubuntu", fontSize:"20px", textAlign:"justify"}}>First Name
                    <input type="text" class="form-control" placeholder="First name" aria-label="First name" style={{position:"relative", top:"15px", color:"#545454"}}/>
                </div>
                <div class="col" style={{position:"relative", top:"20px", fontFamily:"ubuntu", fontSize:"20px", textAlign:"justify"}}>Last Name
                    <input type="text" class="form-control" placeholder="Last name" aria-label="Last name" style={{position:"relative", top:"15px", color:"#545454"}}/>
                </div>
                </div>
                <div class="row mb-3">
                <div className='col'>
                    <label for="exampleFormControlInput1" class="form-label" style={{position:"relative", top:"40px", fontSize:"20px", textAlign:"center"}}>Email address</label>
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" style={{position:"relative", top:"40px", color:"#545454" }}/>
                </div>                
                </div>
                <div class="mb-3 row">
                <div className='col'>
                    <label for="exampleFormControlTextarea1" class="form-label" style={{position:"relative", top:"30px", fontSize:"20px", textAlign:"justify"}}>Message</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Optional' style={{position:"relative", top:"30px", color:"#545454"}}></textarea>
                </div>
                </div>

                 <div className='row'>
                   
                    <div class="col-xl-12">
                    
                        <center><button class="btn btn-submit" style={{position:"relative", top:"30px", backgroundColor:"#ffbd59", width:"250px", color:"#545454"}} type="button">Submit</button></center>
  
                    </div>
                    </div>    
                </div>
                <div className='row text-justify' style={{position:"absolute", left:"0px", width:"500px", top:"125px", color:"white"}}>
                    <div className='col-xl-12'>
                    <div class="d-flex flex-column mb-3 text-center " style={{fontSize:"20px"}}>
                        <div class="p-3" style={{position:"relative", left:"25px", color:"#0097b2", backgroundColor:"#ebe8e8", boxShadow:"#545454", borderRadius:"5px", width:"275px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                        </svg><br/>
                            G-181, Venus Appartment, Sector-9, Rohini, New Delhi-110085</div>
                        <div class="p-3" style={{position:"relative", left:"25px", color:"#0097b2", backgroundColor:"#ebe8e8", boxShadow:"#545454", borderRadius:"5px", width:"275px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                        </svg><br/>
                            +91-9711133976<br/>+91-9711573551
                        </div>
                        <div class="p-3" style={{position:"relative", left:"25px", color:"#0097b2", backgroundColor:"#ebe8e8", boxShadow:"#545454", borderRadius:"5px", width:"275px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-at-fill" viewBox="0 0 16 16">
                        <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671"/>
                        <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z"/>
                        </svg><br/>
                        shivanimjindal@gmail.com</div>
                    </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
            
        </div>
    );
}

export default Contact;