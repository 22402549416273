
import Footer from '../components/Footer';
import Header from '../components/Header';
import './Home.css';

    const Home=()=>{
        return(
        <div className='container-fluid'>
         
          
  <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner" style={{height:"100vh", width:"1000px"}}>
    <div className="carousel-item active" data-bs-interval="1000">
      <img src="images/digi_marketng.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item" data-bs-interval="1000">
      <img src="images/web_design.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item" data-bs-interval="1000"> 
      <img src="images/seo.jpg" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item" data-bs-interval="1000">
      <img src="images/social.jpg" className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>

  <div className='content-box container-fluid'>
    <div className='row'>
      <div className='col-xl-12 text-center'>
      <p>Innovations with DiziTech</p>
      </div>
      
      <div className='row'>
        <div className='col-xl-3'>
          <hr/>
        </div>
        <div className='col-xl-9'>
        <p style={{textAlign:"justify", fontSize:"18px", color:"#545454", fontFamily:"ubuntu"}}>
          DiziTech Space is all about technology whether it is related to developing websites or transforming your business.  </p>
        </div>
      </div>
    </div>
    
    
  </div>
</div><br/><br/><br/><br/><br/>

<div className='row' style={{backgroundColor:"#0097b2", height:"400px", width:"100%", borderRadius:"5px"}}>
  <p style={{textAlign:"justify",color:"white", fontSize:"36px", fontFamily:"ubuntu", position:"relative", top:"18px", left:"54px"}}>Business Transformation</p>
        <p style={{color:"white", textAlign:"justify", fontFamily:"ubuntu", fontSize:"18px", position:"relative", top:"40px"}}>Transforming the business means successful transformation <br/>while considering all the important pillars beyond technology.</p>

  <div className="row row-cols-3 row-cols-md-3 cardstyle">
  <div className="col">
    <div className="card blockstyle" >
      <img src="images/experience.jpg" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Experience</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>People's experiences whether employees or customers help in growing the business.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card blockstyle">
      <img src="images/people.jpg" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>People</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>Right talent, right people improve the organization with the right opportunity .</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card blockstyle">
      <img src="images/change.jpg" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Change</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify",fontFamily:"ubuntu"}}>Change is the need of life. Develop a strategy that makes people get changed with time.</p>
      </div>
    </div>
  </div><br/><br/><br/><br/>
  <div className="col">
    <div className="card blockstyle">
      <img src="images/innovation.jpg" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Innovation</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>Innovations are creativity that changes the face of your business.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card blockstyle">
      <img src="images/leadership.jpg" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Leadership</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>Leadership is the initiative that changes the view of the organiztion.Work like a leader, not a boss.</p>
      </div>
    </div>
  </div>
  <div className="col ">
    <div className="card blockstyle">
      <img src="images/culture.jpg" height="189px" className="card-img-top" alt="..."/>
      <div className="card-body vl">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Culture</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>Culture refers to business culture where employees and customers are reign supreme.</p>
      </div>
    </div>
  </div>
</div>

</div>




       
</div> 
      
        );
    }

    export default Home;