import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';

function App() {
  return (
   
    
    
    
            <div>
              
              <Header/>
                
              <Routes>
                  <Route path='/' element={<Home/>}></Route>
                  <Route path='portfolio' element={<Portfolio />}></Route>
                  <Route path='services' element={<Services/>}></Route>
                  <Route path='about' element={<About/>}></Route>
                  <Route path='contact' element={<Contact/>}></Route>
                </Routes>
                
                
                
                </div>
              
            
            
           
          
          
            
         
       
   
      
          
    
  );
}

export default App;
