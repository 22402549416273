import Header from '../components/Header';
import './About.css';

const About=()=>{
    return(
        <div>
            
            <div className='row'>
                
                <div className='col-xl-12'>
                
                    <div className='content-box container-fluid' style={{position:"relative", left: "0px", top:"0px", height:"450px", width:"1000px",backgroundImage:"url('images/image2.jpg')", borderRadius:"5px"}}>
                    <div className='about-box text-left' style={{fontFamily:"ubuntu"}}>WE !  Transform your world</div>
                    <div className='about-content text-center'> Unlock the future with tranforming your business to digitalization. We are in the prgressing world.
                                                    Everything is becoming digitalise. Like as, education, shopping, marketing etc.<br></br><br></br>

                                                    <div>At the time of pandemic, the people which are having offline work faces many problems.
                                                    This can be a problem at any time, anywhere, so we should all learn about digital world 
                                                    from doing online business to online education.</div><br></br>

                                                    <div>We help you to transform you offline business to online. This can enhance your business in the way that 
                                                    you can run your business in both mode.</div><br></br>

                                                    <div style={{fontSize:"20px"}}>Give us the chance to be a part of your growth.</div>
                    </div>
                    </div>
                </div>
                
        </div>
        </div>
    );
}

export default About;