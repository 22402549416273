import Header from '../components/Header';


const Services=()=>{
    return(
        <div>
            
            <div className='row img-fluid' style={{backgroundImage:"url('images/image2.jpg')", height:"400px", width:"100%", borderRadius:"5px"}}>
            <p style={{textAlign:"left",color:"#ffbd59", fontSize:"48px", fontFamily:"ubuntu", position:"relative", top:"18px", left:"54px"}}>Services</p>
        

  <div className="row row-cols-3 row-cols-md-3 cardstyle">
  <div className="col">
    <div className="card portstyle" >
      <img src="images/digitalize.jpg" height="163px" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Digital Transformation</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>Making the business digital through which it can be easily available to everyone.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card portstyle">
      <img src="images/website.jpg" height="163px" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Website Development</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify", fontFamily:"ubuntu"}}>Create your website from designing to deploying helps in business enhancement. </p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card portstyle">
      <img src="images/logo.jpg" height="163px" className="card-img-top" alt="..."/>
      <div className="card-body">
        <h5 className="card-title  text-center" style={{color:"#0097b2", fontFamily:"ubuntu"}}>Logo Creation</h5>
        <p className="card-text" style={{color:"#545454", textAlign:"justify",fontFamily:"ubuntu"}}>Innovative, Meaningful, Stylish logo creation as per customer needs.</p>
      </div>
    </div>
  </div><br/><br/><br/><br/>
  
  
  
</div>

</div>
</div>
    );
}

export default Services;